import { patch} from '@rails/request.js'

export const possibleModal = () => {
  document.querySelectorAll('.is-lightbox[href$="#possible-modal"]').forEach((element) => {
    element.classList.remove('is-lightbox')
    element.href = element.href.replace('#possible-modal', '')
    element.dataset.modalIdValue = 'modal-content-dynamic'
    element.dataset.modalFrameValue = JSON.stringify({ src: element.href, id: 'dynamic-frame' })
    element.dataset.modalTitleValue = ''
    element.dataset.modalCloseOnFormSuccessValue = '0'
    element.dataset.modalDestroyOnCloseValue = '1'
    element.dataset.controller = 'modal'
  })
}

export const acceptProposal = () => {
  const proposalMatch = window.location.pathname.match(/\/p\/(.*)$/g)

  if (!proposalMatch) return

  const proposalPath = proposalMatch[0]

  const submitForm = async (e) => {
    e.preventDefault()

    const { response } = await patch(proposalPath, {
      body: {
        proposal: { status: 'accepted' }
      },
      responseKind: 'json',
    })

    if (response.ok) {
      const json = await response.json()
      window.location.href = json.redirect_to
    }
  }

  if (proposalPath) {
    document.querySelectorAll('[href$="#accept-current-proposal"]').forEach((element) => {
      element.addEventListener('click', submitForm)
    })
  }
}
